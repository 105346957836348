import { Suspense, useEffect } from 'react';
import PageLoader from '../components/shared/page-loader/PageLoader';
import { FCWithChildren } from '../types/FCWithChildren';
import { useLayoutMargins } from '../global-state/Layout';

const MinimalLayout: FCWithChildren<unknown> = (props) => {
  const { children } = props;
  const setLayoutMargins = useLayoutMargins((x) => x.setValue);

  useEffect(() => {
    setLayoutMargins('');

    return () => setLayoutMargins('');
  }, [setLayoutMargins]);

  return (
    <div className="h-full w-full">
      <Suspense fallback={<PageLoader loading isSuspense />}>{children}</Suspense>
    </div>
  );
};

export default MinimalLayout;
